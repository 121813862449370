<template>
    <div>
        <link rel="canonical" href="https://www.soyego.com" />
        <link rel="alternate" media="only screen and (max-width: 640px)" href="http://m.soyego.com">
        
        <div class="index-banner" v-loading="carouselLoading">
            <el-carousel trigger="click" :height="bannerHeight+'px'" :interval="6000" v-if="carouselData[0]!=null">
                <el-carousel-item v-for="(item, index) in carouselData" :key="index">
                    <el-image  :src="$img(item.imageUrl)" fit="cover" @click="$router.push({ path: '/newIn?categoryId=' + item.link.wap_url })" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="index-discount" v-if="!loadLikeing">
            <div class="discount-container">
                <div class="discount-item" v-for="(item, index) in rubikCube" :key="index" @click="$router.push({ path: '/newIn?categoryId=' + item.link.name })">
                    <el-image :src="$img(item.imageUrl)" fit="contain" />
                </div>
            </div>
        </div>
        <div class="index-category" v-if="!loadLikeing">
            <div class="category-container">
                <div class="category-title">
                    <span>Shop By Category</span>
                </div>
                <div class="category-list">
                    <div class="category-item"  v-for="(item,index) in categoryData" @click="$router.push({ path: '/newIn?categoryId=' + item.link.name })">
                        <div class="item-left">
                            <div class="left-sketch">
                                <el-image :src="$img(item.imageUrl)" fit="cover" />
                            </div>
                            <div class="left-title">{{item.title}}</div>
                        </div>
                        <div class="item-right">
                            <el-image :src="$img(item.imageBigUrl)" fit="cover" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="index-blowoutSale" v-if="!loadLikeing">
            <div class="blowoutSale-container">
                <div class="blowoutSale-banner" @click="$router.push({ path: '/newIn?categoryId=' + blowoutSaleLink })">
                    <el-image :src="$img(blowoutSaleBanner)" fit="cover" />
                </div>
                <div class="blowoutSale-goodsList">
                    <div class="goodsList-item" v-for="(item,index) in blowoutSaleData">
                        <div class="item-cover" @click="detailsClick(item.sku_id)">
                            <el-image :src="$img(item.sku_image)" fit="cover" />
                        </div>
                        <div class="item-goodsName">{{item.sku_name}}</div>
                        <div class="item-price">{{item.str_cur_x}}{{item.price}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="index-newIn" v-if="!loadLikeing">
            <div class="newIn-container">
                <div class="newIn-header">
                    <div class="header-left">NEW IN</div>
                    <div class="header-right" @click="$router.push({ path: '/newIn?categoryId=' + newInLink })">
                        <span>VIEW ALL</span>
                    </div>
                </div>
                <div class="newIn-banner" @click="$router.push({ path: '/newIn?categoryId=' + newInLink })">
                    <el-image :src="$img(newInBanner)" fit="cover" />
                </div>
                <div class="newIn-goodsList">
                    <div class="goodsList-item" v-for="(item,index) in newInGoodsData">
                        <div class="item-cover" @click="detailsClick(item.sku_id)">
                            <el-image :src="$img(item.goods_image[0])" fit="cover" />
                        </div>
                        <div class="item-goodsName">{{item.goods_name}}</div>
                        <div class="item-price">{{item.str_cur_x}}{{item.price}}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="index-trending">
            <div class="trending-container">
                <div class="trending-header">
                    <div class="header-left">TRENDING</div>
                    <div class="header-right">
                        <span>VIEW ALL</span>
                    </div>
                </div>
                <div class="trending-goodsList">
                    <div class="goodsList-item">
                        
                    </div>
                </div>
            </div>
        </div> -->
        <div class="index-recommen" v-loading="loadLikeing">
            <div class="recommen-container">
                <div class="recommen-header">
                    <span>Recommendations</span>
                </div>
                <div class="recommen-goodsList">
                    <div class="goodsList-item" v-for="(item,index) in likeList">
                        <div class="item-cover" @click="detailsClick(item.sku_id)">
                            <el-image :src="$img(item.sku_image)" fit="cover" />
                        </div>
                        <div class="item-goodsName">{{item.sku_name}}</div>
                        <div class="item-price">{{item.str_cur_x}}{{item.price}}</div>
                    </div>
                </div>
                <div class="recommen-more">
                    <div class="more-button" @click="getGoodsLike(true)">
                        <span>View More</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import index from "./_index/index.js"
// import floorStyle1 from "./components/floor-style-1.vue"
// import floorStyle2 from "./components/floor-style-2.vue"
// import floorStyle3 from "./components/floor-style-3.vue"
// import Notice from "./_index/components/Notice.vue"
// import DiscountCode from "./_index/components/discountCode.vue"
// import BagShop from "@/views/shop/components/bagShop.vue"
// import Trending from "./_index/components/trending.vue"
// import Banner from "./_index/components/banner.vue"
// import RubikCube from "./_index/components/rubikCube.vue"
// import ImgAdvert from "./_index/components/imgAdvert.vue"
// import ImgTextNav from "./_index/components/imgTextNav.vue"



export default {
    name: "index",
    components: {
        // floorStyle1,
        // floorStyle2,
        // floorStyle3,
        // Notice,
        // DiscountCode,
        // BagShop,
        // Trending,
        // Banner,
        // RubikCube,
        // ImgAdvert,
        // ImgTextNav
    },
    mixins: [index],
    created() {
        // this.getTranslateText()
    },
    data() {
        return {
            // 需要翻译的静态文本
            // translateTextData: {
            //     NEW: 'NEW',
            //     DAILY_DROPS: 'DAILY DROPS',
            //     See_more: 'See more',
            //     Recommendations: 'Recommendations',
            //     View_More: 'View More'
            // }
        }
    },
    methods: {
        // 翻译静态文本
        // getTranslateText() {
        //     let strText = '';
        //     let i = 0;
        //     let translateData = Object.values(this.translateTextData); // 创建一个包含对象中每个属性的值的数组
        //     translateData.forEach((item,index) => {
        //         strText += item + '\n'
        //     })
        //     // 翻译文本
        //     this.$fanyi.translateData({
        //         query: strText,
        //         success: resData => {
        //             for(let key in this.translateTextData){
        //                 this.translateTextData[key] = resData[i]
        //                 i++;
        //             }
        //         }
        //     })
        // },
    }
}
</script>

<style>
.about-content{
    background: #fff;
}
/* .content-trendingMain .trending-main .el-carousel__indicators--horizontal{
    display: none;
} */

</style>
<style lang="scss" scoped>
@import "./_index/index.scss";
</style>

<style lang="scss" scoped>
.count-down {
    span {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: #383838;
        color: #ffffff;
        border-radius: 2px;
    }
}

.more {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: flex-end;
    a {
        color: #ff547b;
    }
}
.goods-info {
    width: 1200px;
    margin: 5px auto 40px;
    display: flex;
    flex-wrap: wrap;
    .item {
        width: 202px;
        margin: 10px 20px 0 0;
        border: 1px solid #eeeeee;
        padding: 10px;
        position: relative;
        &:nth-child(5n) {
            margin-right: initial !important;
        }
        &:hover {
            border: 1px solid $base-color;
        }
        .img-wrap {
            width: 198px;
            height: 198px;
            cursor: pointer;
        }
        .goods-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
            &:hover {
                color: $base-color;
            }
        }
        .price-wrap {
            display: flex;
            align-items: center;
            .price {
                display: flex;
                color: $base-color;
                font-size: $ns-font-size-lg;
                p {
                    font-size: $ns-font-size-base;
                    display: flex;
                    align-items: flex-end;
                }
            }
            .market-price {
                color: #838383;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }
        .sale-num {
            display: flex;
            color: #838383;
            p {
                color: #4759a8;
            }
        }
        .saling {
            display: flex;
            font-size: $ns-font-size-sm;
            line-height: 1;
            .free-shipping {
                background: $base-color;
                color: #ffffff;
                padding: 3px 4px;
                border-radius: 2px;
                margin-right: 5px;
            }
            .promotion-type {
                color: $base-color;
                border: 1px solid $base-color;
                display: flex;
                align-items: center;
                padding: 1px;
            }
        }
    }
}
</style>
<!-- 首页改版样式 -->
<style lang="scss" scoped>
    .index-banner{
        width: 100%;
        height: calc(100vw * 803 / 1920);
        .el-image{
            width: 100%;
            height: 100%;
        }
    }
    .index-discount{
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 3vh 0px;
        .discount-container{
            width: 93.95vw;
            display: flex;
            justify-content: space-between;
            // position: relative;
            .discount-item{
                width: 22.08vw;
                height: calc(22.08vw * 560 / 424);
                cursor:pointer;
                .el-image{
                    width: 100%;
                    height: 100%;
                    transform: scale(1);
                    transition: all 0.2s;
                    &:hover{
                        transform:scale(1.05);
                    }
                }
            }
        }
    }
    .index-category{
        width: 100%;
        padding: 80px 0px;
        background-color: #F8F8F8;
        display: flex;
        justify-content: center;
        .category-container{
            width: calc(100vw - 220px);
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .category-title{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                span{
                    font-size: 46px;
                    line-height: 46px;
                    font-family: 'Bold';
                    color:#333333;
                }
            }
            .category-list{
                width: 88.54vw;
                float: left;
                margin-top: 60px;
                overflow: hidden;
                &:hover{
                    .category-item{
                        width: 7.8vw;
                    }
                }
                .category-item{
                    width: 7.8vw;
                    height: calc(33.85vw * 640 / 650);
                    float: left;
                    position: relative;
                    transition: all .8s;
                    cursor:pointer;
                    &:hover{
                        width: 33.85vw;
                        .item-left{
                            background-color: #FFFFFF;
                        }
                    }
                    .item-left{
                        width: 7.8vw;
                        height: calc(7.8vw * 638 / 150);
                        border-top: 1px solid #999999;
                        border-bottom: 1px solid #999999;
                        border-left: 1px solid #999999;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #F8F8F8;
                        .left-sketch{
                            width: 3.43vw;
                            height: calc(3.43vw * 66 / 66);
                            .el-image{
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .left-title{
                            margin-top: 28px;
                            color: rgba(51, 51, 51, 1);
                            font-size: 12px;
                        }
                    }
                    .item-right{
                        width: 26.04vw;
                        height: calc(26.04vw * 640 / 500);
                        background-color: #999999;
                        position: absolute;
                        top:0;
                        left: 7.8vw;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .category-cover{
                    width: 26.04vw;
                    height: calc(26.04vw * 640 / 500);
                    background-color: aqua;
                    .el-image{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .index-blowoutSale{
        width: 100%;
        padding: 8vh 0px;
        display: flex;
        justify-content: center;
        .blowoutSale-container{
            width: 93.95vw;
            display: flex;
            flex-direction: column;
            .blowoutSale-banner{
                width: 93.95vw;
                height:calc(93.95vw * 550 / 1804);
                background-color: aquamarine;
                cursor: pointer;
                overflow: hidden;
                .el-image{
                    width: 100%;
                    height: 100%;
                    transition: all 0.6s;
                    &:hover{
                        transform:scale(1.05);
                    }
                }
            }
            .blowoutSale-goodsList{
                width: 93.95vw;
                display: flex;
                justify-content: space-between;
                margin-top: 40px;
                .goodsList-item{
                    width: 17.81vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .item-cover{
                        width: 17.81vw;
                        height:calc(17.81vw * 454 / 342);
                        cursor:pointer;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item-goodsName{
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        min-height: 42px;
                        line-height: 20px;
                        margin-top: 22px;
                        /* 1.溢出隐藏 */
                        overflow: hidden;
                        /* 2.用省略号来代替超出文本 */
                        text-overflow: ellipsis;
                        /* 3.设置盒子属性为-webkit-box  必须的 */
                        display: -webkit-box;
                        /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                        -webkit-line-clamp: 2;
                        /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                        word-break: break-all;
                        /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                        -webkit-box-orient: vertical;
                        text-align: center;
                    }
                    .item-price{
                        color: rgba(51, 0, 0, 1);
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 500;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .index-newIn{
        width: 100%;
        padding: 8vh 0px;
        display: flex;
        justify-content: center;
        .newIn-container{
            width: 93.95vw;
            display: flex;
            flex-direction: column;
            .newIn-header{
                width: 93.95vw;
                padding-bottom: 20px;
                border-bottom: 1px solid #ADA6A6;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .header-left{
                    font-size: 26px;
                    line-height: 26px;
                    color: #330000;
                }
                .header-right{
                    width: 9.79vw;
                    height:calc(9.79vw * 42 / 188);
                    border: 1px solid #333;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor:pointer;
                    transition: all .25s;
                    &:hover{
                        background-color: #330000;
                        span{
                            color:#FFF;
                        }
                    }
                }
            }
            .newIn-banner{
                margin-top: 60px;
                width: 93.95vw;
                height:calc(93.95vw * 550 / 1804);
                background-color: #330000;
                cursor: pointer;
                overflow: hidden;
                .el-image{
                    width: 100%;
                    height: 100%;
                    transition: all 0.6s;
                    &:hover{
                        transform:scale(1.05);
                    }
                }
            }
            .newIn-goodsList{
                width: 85.01vw;
                margin-top: 40px;
                display: flex;
                // justify-content: space-between;
                padding: 0px 4.47vw;
                overflow: hidden;
                .goodsList-item{
                    width:20.31vw;
                    margin-left:1.25vw;
                    &:first-child{
                        margin-left: 0;
                    }
                    .item-cover{
                        width:20.31vw;
                        height:calc(20.31vw * 400 / 390);
                        background-color: #ADA6A6;
                        cursor:pointer;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item-goodsName{
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        min-height: 42px;
                        line-height: 20px;
                        margin-top: 22px;
                        /* 1.溢出隐藏 */
                        overflow: hidden;
                        /* 2.用省略号来代替超出文本 */
                        text-overflow: ellipsis;
                        /* 3.设置盒子属性为-webkit-box  必须的 */
                        display: -webkit-box;
                        /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                        -webkit-line-clamp: 2;
                        /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                        word-break: break-all;
                        /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                        -webkit-box-orient: vertical;
                        text-align: center;
                    }
                    .item-price{
                        color: rgba(51, 0, 0, 1);
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 500;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }
        }
    }
    .index-trending{

    }
    .index-recommen{
        width: 100%;
        padding: 8vh 0px;
        display: flex;
        justify-content: center;
        .recommen-container{
            width: 93.95vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            .recommen-header{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                span{
                    color: #333333;
                    font-size: 46px;
                    font-weight: Bold;
                }
            }
            .recommen-goodsList{
                width: 93.95vw;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                // margin-top: 60px;
                .goodsList-item{
                    width: 17.70vw;
                    display: flex;
                    flex-direction: column;
                    margin-top: 50px;
                    .item-cover{
                        width: 17.70vw;
                        height: calc(17.70vw * 454 / 340);
                        background-color: #330000;
                        cursor:pointer;
                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .item-goodsName{
                        color: rgba(51, 51, 51, 1);
                        font-size: 16px;
                        line-height: 20px;
                        min-height: 42px;
                        margin-top: 22px;
                        /* 1.溢出隐藏 */
                        overflow: hidden;
                        /* 2.用省略号来代替超出文本 */
                        text-overflow: ellipsis;
                        /* 3.设置盒子属性为-webkit-box  必须的 */
                        display: -webkit-box;
                        /* 4.-webkit-line-clamp 设置为2，表示超出2行的部分显示省略号，如果设置为3，那么就是超出3行部分显示省略号 */
                        -webkit-line-clamp: 2;
                        /* 5.字面意思：单词破坏：破坏英文单词的整体性，在英文单词还没有在一行完全展示时就换行  即一个单词可能会被分成两行展示 */
                        word-break: break-all;
                        /* 6.盒子实现多行显示的必要条件，文字是垂直展示，即文字是多行展示的情况下使用 */
                        -webkit-box-orient: vertical;
                        text-align: center;
                    }
                    .item-price{
                        color: rgba(51, 0, 0, 1);
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 500;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }
            .recommen-more{
                width: 100%;
                display: flex;
                justify-content: center;
                margin-top:40px;
                .more-button{
                    width:22.30vw;
                    height:calc(22.30vw * 64 / 428);
                    background-color: #330000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    span{
                        color:#fff;
                        font-size:18px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
</style>

<!-- 媒体查询 小于1140px -->
<style lang="scss" scoped>
@media (max-width: 1140px) {
    .index-discount{
        padding: 1vh 0px;
    }
    .index-category{
        padding: 40px 0px;
        .category-container{
            .category-title{
                span{
                    font-size: 36px;
                }
            }
            .category-list{
                margin-top: 40px;
            }
        }
    }
    .index-blowoutSale{
        padding: 4vh 0px;
        .blowoutSale-container{
            .blowoutSale-goodsList{
                margin-top: 20px;
                .goodsList-item{
                    .item-goodsName{
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .index-newIn{
        padding: 4vh 0px;
        .newIn-container{
            .newIn-banner{
                margin-top: 30px;
            }
            .newIn-goodsList{
                margin-top: 20px;
                .goodsList-item{
                    .item-goodsName{
                        margin-top: 16px;
                    }
                }
            }
        }
    }
    .index-recommen{
        padding: 4vh 0px;
        .recommen-container{
            .recommen-header{
                span{
                    font-size: 36px;
                }
                
            }
            .recommen-goodsList{
                .goodsList-item{
                    margin-top: 30px;
                }
            }
            .recommen-more{
                margin-top: 40px;
                .more-button{
                    span{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
</style>

<!-- 媒体查询 小于860px -->
<style lang="scss" scoped>
@media (max-width: 860px) {
    .index-discount{
        padding: 1vh 0px;
    }
    .index-category{
        padding: 20px 0px;
        .category-container{
            .category-title{
                span{
                    font-size: 26px;
                }
            }
            .category-list{
                margin-top: 20px;
            }
        }
    }
    .index-blowoutSale{
        padding: 2vh 0px;
        .blowoutSale-container{
            .blowoutSale-goodsList{
                margin-top: 20px;
                .goodsList-item{
                    .item-goodsName{
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .index-newIn{
        padding: 2vh 0px;
        .newIn-container{
            .newIn-banner{
                margin-top: 30px;
            }
            .newIn-goodsList{
                margin-top: 20px;
                .goodsList-item{
                    .item-goodsName{
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .index-recommen{
        padding: 2vh 0px;
        .recommen-container{
            .recommen-header{
                span{
                    font-size: 26px;
                }
            }
            .recommen-goodsList{
                .goodsList-item{
                    margin-top: 30px;
                    .item-goodsName{
                        margin-top: 16px;
                        font-size: 14px;
                    }
                }
            }
            .recommen-more{
                margin-top: 26px;
                .more-button{
                    span{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>

